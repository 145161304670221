import React, { useCallback, useEffect } from "react";

import { useDeliveryStore } from "../stores/hooks";

function Index(props) {
  const { id: orderIdFromCorp } = props.match.params;

  const { state, ...actions } = useDeliveryStore();

  useEffect(() => {
    fetchImageSignature();
    fetchImageDeliveryCompleted();
  }, [orderIdFromCorp]);

  const fetchImageSignature = useCallback(() => {
    if (orderIdFromCorp) {
      actions.fetchImageSignature(orderIdFromCorp);
    }
  }, [orderIdFromCorp]);

  const fetchImageDeliveryCompleted = useCallback(() => {
    if (orderIdFromCorp) {
      actions.fetchImageDeliveryCompleted(orderIdFromCorp);
    }
  }, [orderIdFromCorp]);

  return (
    <div className="container">
      {state.imageSignature && (
        <img className="image" src={state.imageSignature} alt={`${orderIdFromCorp}의 서명이미지`} />
      )}
      {state.imageDeliveryCompleted && (
        <img className="image" src={state.imageDeliveryCompleted} alt={`${orderIdFromCorp}의 서명이미지`} />
      )}
    </div>
  );
};

export default Index;
