import React, { createContext, useCallback, useContext, useReducer } from "react";

import api from "../Api";

export const CONSTANTS = {
  FETCH_IMAGE_DELIVERY_COMPLETED: "FETCH_IMAGE_DELIVERY_COMPLETED",
  FETCH_IMAGE_SIGNATURE: "FETCH_IMAGE_SIGNATURE",
};

const INITIAL_STATE = {
  imageDeliveryCompleted: null,
  imageSignature: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_IMAGE_DELIVERY_COMPLETED:      
      return {
        ...state,
        imageDeliveryCompleted: action.imageDeliveryCompleted,
      };
    case CONSTANTS.FETCH_IMAGE_SIGNATURE:      
      return {
        ...state,
        imageSignature: action.imageSignature,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveryStore = () => {
  const { dispatch, state } = useContext(Context);

  const fetchImageDeliveryCompleted = useCallback(async (orderIdFromCorp) => {
    const result = await api.get(`/public/images/corp-users/cjlogistics/${orderIdFromCorp}/delivery-completed`);

    if (result) {
      dispatch({ 
        type: CONSTANTS.FETCH_IMAGE_DELIVERY_COMPLETED, 
        imageDeliveryCompleted: result.image,
      });
    };
  }, [api.get, dispatch]);

  const fetchImageSignature = useCallback(async (orderIdFromCorp) => {
    const result = await api.get(`/public/images/corp-users/cjlogistics/${orderIdFromCorp}/signature`);

    if (result) {
      dispatch({ 
        type: CONSTANTS.FETCH_IMAGE_SIGNATURE, 
        imageSignature: result.image,
      });
    };
  }, [api.get, dispatch]);

  return {
    state,
    fetchImageDeliveryCompleted,
    fetchImageSignature,
  };
};
