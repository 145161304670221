import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Main from "./pages/index";

import { DeliveryStore } from './stores';

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children,
  );

function App() {
  return (
    <AppProvider
      contexts={[DeliveryStore.Provider]}
    >
      <BrowserRouter>
        <Switch>
          <Route exact path="/:id" component={Main} />
        </Switch>
      </BrowserRouter>
    </AppProvider>
);
}

export default App;
